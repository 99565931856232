<template>
  <div class="container admin-users-container">
    <div class="row justify-content-between">
      <h1 class="col-auto">Admin Users</h1>
      <div
        class="col-auto text-right"
        v-if="$can({ key: 'adminUser', expectedPermission: 'create' })"
      >
        <router-link
          to="/admin/users/create"
          class="btn btn-outline-blue btn-sm"
        >
          <icon type="plus" /> Add New User
        </router-link>
      </div>
    </div>
    <alert v-if="loading" class="light-shadow" />
    <div
      class="card light-shadow overflow-auto mobile-tablet-no-card"
      v-if="!loading"
    >
      <div class="card-body" v-if="!loading && currentUser">
        <div class="d-flex justify-content-end flex-wrap">
          <div
            class="
              d-flex
              align-items-center
              mr-auto
              text-dark
              col-6 col-md-auto
              mb-3
              pl-0
            "
          >
            <span>
              <b v-if="!loading">
                <i class="fas fa-spin fa-circle-notch" v-if="busy"></i>
                {{ busy ? "" : users.length }}/{{ total }}</b
              >
              <i class="fas fa-spin fa-circle-notch" v-if="loading"></i>
              Admin{{ loading || total != 1 ? "s" : "" }}
            </span>
          </div>
          <div class="d-flex justify-content-end m-0">
            <select-control
              v-model="userType"
              :options="userTypes"
              type="horizontal"
              class="mr-3 users-types"
              @input="getFilteredUsers()"
            ></select-control>
          </div>
        </div>
        <div class="form-group mb-0" v-if="!loading">
          <input
            type="search"
            class="form-control pr-5"
            @keydown.enter="SearchAdminText"
            v-model.trim="searchContent"
            placeholder="Search by Email Address, First or Last Name"
          />
          <span>
            <i
              class="fa fa-search search-icon text-black"
              @click="SearchAdminText"
            ></i>
            <i
              v-if="searchContent"
              class="fas fa-times cancel-icon"
              @click="(searchContent = ''), SearchAdminText()"
            ></i>
          </span>
        </div>
      </div>
      <div class="overflow-auto" v-if="users.length && !loading">
        <table
          class="table table-striped responsive-mobile-tablet-table"
          :class="users.length ? 'mb-0' : ''"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Profile</th>
              <th>First</th>
              <th>Last</th>
              <th>Gender</th>
              <th>Email</th>
              <th>User Name</th>
              <th class="text-center">Manager</th>
              <th class="text-center">Status</th>
              <th
                v-if="$can({ key: 'adminUser', expectedPermission: 'edit' })"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="u in users" :key="u.id">
              <td><b class="mobile-tablet-only mr-2">ID:</b>{{ u.id }}</td>
              <td>
                <div
                  class="
                    d-inline-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <b class="mobile-tablet-only mr-2">Profile:</b>
                  <div class="position-relative">
                    <img
                      class="user-avatar-img rounded-circle"
                      :src="u.profile_picture || profileImagePlaceholder"
                    />
                    <div
                      class="user-status"
                      :class="{
                        online: isOnline(u.id),
                        offline: !isOnline(u.id),
                      }"
                    />
                  </div>
                </div>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">First:</b>{{ u.first }}
              </td>
              <td><b class="mobile-tablet-only mr-2">Last:</b>{{ u.last }}</td>
              <td>
                <b class="mobile-tablet-only mr-2">Gender:</b
                >{{
                  u.gender == "1" ? "Male" : u.gender == "2" ? "Male" : "Other"
                }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Email:</b
                ><a :href="`mailto:${u.email}`">{{ u.email }}</a>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Username:</b>{{ u.username }}
              </td>
              <td class="text-center">
                <b class="mobile-tablet-only mr-2">Manager:</b>
                <span
                  :class="u.isManagingAdmin ? 'text-success' : 'text-danger'"
                >
                  <icon :type="u.isManagingAdmin ? 'check' : 'times'" />
                </span>
              </td>
              <td class="text-center">
                <b class="mobile-tablet-only mr-2">ٍStatus:</b>
                <span
                  :class="u.status == `ACTIVE` ? `text-success` : `text-danger`"
                >
                  <icon :type="u.status == `ACTIVE` ? `check` : `times`" />
                  {{ u.status }}
                </span>
              </td>
              <td
                v-if="$can({ key: 'adminUser', expectedPermission: 'edit' })"
                class="text-right td-actions"
              >
                <router-link :to="`/admin/users/${u.id}`">
                  <button class="btn btn-sm btn-black">Edit</button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <mugen-scroll :handler="loadMore" :should-handle="!busy && !loading">
          <alert
            v-if="busy"
            class="
              text-center
              p-2
              mb-0
              mobile-tablet-loadmore mobile-tablet-shadow-card
            "
            ><i class="fas fa-spin fa-circle-notch mr-2"></i>Loading more
            admins/managers...</alert
          >
        </mugen-scroll>
      </div>
      <div
        class="card-body pb-0"
        v-if="currentUser && !users.length && !loading"
      >
        <alert>
          No Admins/Managers,
          <router-link
            v-if="$can({ key: 'adminUser', expectedPermission: 'create' })"
            to="/admin/users/create"
            >Add a new Admin/Manager here</router-link
          >
        </alert>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import MugenScroll from "vue-mugen-scroll";

export default {
  name: "Users",
  components: { MugenScroll },
  data() {
    return {
      busy: false,
      limit: 50,
      searchContent: "",
      latestSearch: "",
      userTypes: [
        { value: -1, label: "All" },
        { value: 1, label: "Admins" },
        { value: 5, label: "Managers" },
      ],
      userType: -1,
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
    };
  },
  mounted() {
    this.getFilteredUsers();
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.user,
      users: (state) => state.adminUsers.adminsManagersUsers.pagination.data,
      total: (state) => state.adminUsers.adminsManagersUsers.pagination.total,
      current: (state) =>
        state.adminUsers.adminsManagersUsers.pagination.current,
      loading: (state) => state.adminUsers.adminsManagersUsers.loading,
      onlineUsers: (state) => state.auth.onlineUsers,
    }),
  },
  methods: {
    ...mapActions({
      getAdminsManagers: "adminUsers/getAdminsManagers",
    }),
    getFilteredUsers: function (page) {
      const payload = {
        limit: this.limit,
        roleId: this.userType,
      };
      if (page) {
        payload.page = page;
      }
      if (this.latestSearch) {
        payload.username = this.latestSearch;
      }
      this.getAdminsManagers(payload).then(() => {
        this.busy = false;
      });
    },
    loadMore() {
      if (this.users.length < this.total) {
        this.busy = true;
        this.getFilteredUsers(this.current + 1);
      }
    },
    SearchAdminText() {
      if (this.latestSearch == this.searchContent) {
        return;
      }
      this.latestSearch = this.searchContent;
      this.getFilteredUsers();
    },
    isOnline: function (id) {
      return (this.onlineUsers || []).includes(id);
    },
  },
};
</script>
<style scoped lang="scss">
.table-continer {
  overflow-x: auto;
}
.admin-users-container {
  @media (max-width: 1024px) {
    .responsive-mobile-tablet-table {
      margin-top: 4px;
    }
    .card-body {
      background-color: white;
    }
    .page-header {
      display: block !important;
    }
  }
}

.cancel-icon,
.search-icon {
  z-index: 99;
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #dc143c;
  cursor: pointer;
}
.search-icon {
  right: 15px;
}
.form-group {
  position: relative;
}

.user-avatar-img {
  width: 45px;
  min-width: 45px;
  height: 45px;
}
.user-status {
  position: absolute;
  right: -1px;
  bottom: 0px;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  border: 1px solid #edf2f9;
  &.online {
    background-color: #4caf50;
  }
  &.offline {
    background-color: #9ca6af;
  }
  @media (max-width: 768px) {
    right: 0px;
  }
}
</style>
<style lang="scss">
.users-types {
  .form-group {
    margin-bottom: 0;
  }
  .col {
    padding-right: 0;
  }
  .form-control {
    height: 28px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
